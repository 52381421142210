<template>
  <div class="create-update-page" v-loading="loading">
    <el-form :model="item" label-position="right" label-width="120px" class="form-container">

      <sticky :z-index="10" class-name="sub-navbar">
        <el-button v-loading="saving" style="margin-left: 10px;" type="success" @click="save">
          Сохранить
        </el-button>
      </sticky>

      <div class="main-container">
        <h1 class="title">Вызов на дом</h1>
        <h2 class="subtitle" v-if="!isNew && item.service">{{ item.service.name }}</h2>

        <el-tabs type="border-card" value="info">
            <el-tab-pane label="Инфо" name="info">
                <el-form-item label="Услуга">
                    <multiselect v-model="item.service"
                         :options="services"
                         label="name"
                         :multiple="false"
                         placeholder="Выберите услугу"
                         track-by="uuid"
                         v-if="services"
                    >
                    </multiselect>
                </el-form-item>

                <el-form-item label="Вид приема">
                    <multiselect v-model="item.examination"
                         :options="examinations"
                         label="name"
                         :multiple="false"
                         placeholder="Выберите вид приема"
                         track-by="id"
                         v-if="examinations"
                         :custom-label="examinationLabel"
                    >
                    </multiselect>
                </el-form-item>

                <el-form-item label="Клиники">
                    <multiselect v-model="item.clinics" v-if="clinics"
                         :options="clinics"
                         label="name"
                         :allow-empty="true"
                         :multiple="true"
                         placeholder="Выберите клинику"
                         track-by="uuid"
                    >
                    </multiselect>
                </el-form-item>

                <el-form-item label="Доктора">
                    <multiselect v-model="item.doctors" v-if="doctors"
                         :options="doctors"
                         label="full_name"
                         :allow-empty="true"
                         :multiple="true"
                         :searchable="true"
                         placeholder="Выберите доктора"
                         track-by="uuid"
                    >
                    </multiselect>
                </el-form-item>

                <div style="height: 10em"></div>
            </el-tab-pane>

            <el-tab-pane label="Карта" name="map">
                <el-alert
                    type="info" show-icon
                    style="margin-bottom: 15px"
                >
                    <slot name="title">
                        JSON в формате <a href="https://www.gmapgis.com/" target="_blank">GmapGIS</a>
                    </slot>
                </el-alert>

                <json-editor v-model="item.map"></json-editor>
            </el-tab-pane>
        </el-tabs>
      </div>
    </el-form>
  </div>

<!--<router-link :to="{ name: 'Examinations'}" class="button is-link" exact>Отменить</router-link>-->
</template>

<script>
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import doctorsService from '../../services/doctors'
import clinicService from '../../services/clinics'
import serviceService from '../../services/service'
import examinations from '../../services/examinations'
import houseCallMaps from '../../services/house-call-maps'
import Sticky from '../../components/elements/Sticky'
import JsonEditor from '../../components/elements/JsonEditor'
import { LIST_ITEMS_LIMIT } from '../../consts'

export default {
  components: {
    Sticky,
    JsonEditor
  },
  mixins: [createOrUpdateViewMixin(houseCallMaps, {
    doctors: null,
    examinations: null,
    clinics: null,
    services: null
  })],
  beforeRouteEnter (to, from, next) {
    Promise.all([
      clinicService.getAll({ limit: LIST_ITEMS_LIMIT }),
      doctorsService.getAll({ limit: LIST_ITEMS_LIMIT }),
      examinations.unique(),
      serviceService.getAll({ limit: LIST_ITEMS_LIMIT, filter: { 'only_active': true } })
    ]).then(
      (values) => {
        next(vm => {
          vm.clinics = values[0].clinics
          vm.doctors = values[1].doctors
          vm.examinations = values[2].examinations
          vm.services = values[3].services
        })
      },
      () => { next(false) }
    )
  },
  methods: {
    examinationLabel ({ id, name }) {
      return `(${id}) ${name}`
    }
  }
}
</script>
