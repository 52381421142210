import BaseService from './baseService'
import api from '../utils/api'

class ExaminationApi extends BaseService {
  get url () {
    return 'schedule/examinations'
  }
  unique () {
    return api.get(`${this.url}/unique`)
  }
}

export default new ExaminationApi()

